import { toast } from 'react-toastify';
import { http } from '../../http';
import * as actionTypes from '../actionTypes';
import {CompanyInformation, AddCustomerInfo, GetCompanySummary, GetAllPlans, Subscriptions, SaveCardDetails, Invoices, GetAllAddons, AddAddonsToSubscription, CancelAddon, GetCompanySubscriptionAndAddons, GetBillingTabStatus, UpdateSubscriptions} from '../../constants/apiConstants';

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});
const storeCompanyInformation = (companyInfo) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CompanyInformation, companyInfo, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const storeCustomerInformation = (customerInfo) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.post(AddCustomerInfo, customerInfo, config);
      if(res) {
        toast.success(res.message);
        dispatch(getCompanySummary(customerInfo.company_id));
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const subscriptions = (subscriptionsObject, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.post(Subscriptions, subscriptionsObject, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getCompanySubscriptionAndAddons(companyID));
        dispatch(getInvoices({year: new Date().getFullYear()}, subscriptionsObject['customer_id']))
      }
      dispatch(getCompanySummary(companyID));
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const saveCards = (cardObject, customerId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.post(`${SaveCardDetails}/${customerId}`, cardObject, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getAllCardsForCustomer(customerId));
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getCompanySummary = (companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.get(`${GetCompanySummary}/${companyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_COMPANY_AND_CUSTOMER_DETAILS, companyAndCustomer : res[0]});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getAllPlans = (companyId) =>
  async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const endPoint = !companyId ? GetAllPlans : `${GetAllPlans}/${companyId}`;
      const res = await http.get(endPoint);
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_ALL_PLANS, allPlans : res[0]});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
;
const getInvoices = (object, customerId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.post(`${Invoices}/${customerId}`, object, {headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.GET_ALL_INVOICES, invoices : res});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getAllCardsForCustomer = (customerId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.get(`${SaveCardDetails}/${customerId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_ALL_CARDS_DETAILS, allCardsDetails : res});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const setDefaultCardForCustomer = (defaultCard, customerID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.put(`${AddCustomerInfo}/${customerID}`, defaultCard, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getAllCardsForCustomer(customerID));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const updateSubscriptions = (subscriptionsObject, cusID, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.put(`${UpdateSubscriptions}/${cusID}`, subscriptionsObject, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getCompanySubscriptionAndAddons(companyID));
        dispatch(getInvoices({year: new Date().getFullYear()}, cusID))
      }
      dispatch(getCompanySummary(companyID));
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const deleteCard = (object) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      },
      data: object
    };
    try {
      const res = await http.delete(SaveCardDetails, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getAllCardsForCustomer(object.customer_id));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const cancelSubscription = (object, customerID, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      },
      data: object
    };
    try {
      const res = await http.delete(`${Subscriptions}/${customerID}`, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getCompanySummary(companyID));
        dispatch(getAllCardsForCustomer(customerID));
        dispatch(getCompanySubscriptionAndAddons(companyID));
        dispatch(getInvoices({year: new Date().getFullYear()}, customerID))
      }
      dispatch(getCompanySubscriptionAndAddons(companyID));
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getAllAddons = (companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const endPoint = !companyId ? GetAllAddons : `${GetAllAddons}/${companyId}`;
      const res = await http.get(endPoint, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_ALL_ADDONS_DETAILS, allAddons : res[0]});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const addAddonsToSubscription = (addOnObject, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.post(AddAddonsToSubscription, addOnObject, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
      dispatch(getCompanySummary(companyID));
      dispatch(getCompanySubscriptionAndAddons(companyID));
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const cancelAddon = (addOnObject, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const config = {
      headers: {
        AUTHTOKEN: AuthToken
      }
    };
    try {
      const res = await http.post(CancelAddon, addOnObject, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
      dispatch(getCompanySummary(companyID));
      dispatch(getCompanySubscriptionAndAddons(companyID));
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getCompanySubscriptionAndAddons = (companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      dispatch({type: actionTypes.PAYMENT_LOADING_TRUE});
      const res = await http.get(`${GetCompanySubscriptionAndAddons}/${companyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.GET_COMPANY_SUBSCRPTION_AND_ADDONS_DETAILS, currentSubsciptionDetails : res});
      }
      dispatch({type: actionTypes.PAYMENT_LOADING_FALSE});
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getBillingTabStatus = (companyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetBillingTabStatus}/${companyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(!res.message) {
        dispatch({type: actionTypes.GET_BILLING_TAB_STATUS, billingTabStatus : res['show_billing_tab']});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

export {storeCompanyInformation, storeCustomerInformation, getCompanySummary, getAllPlans, subscriptions, saveCards, getInvoices, getAllCardsForCustomer, setDefaultCardForCustomer, updateSubscriptions, deleteCard, cancelSubscription, getAllAddons, addAddonsToSubscription, cancelAddon, getCompanySubscriptionAndAddons, getBillingTabStatus};